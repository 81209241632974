import React from "react"
//import { FaBeer } from 'react-icons/fa';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, Banner } from '../utils'
import teamsImg from '../images/bg/teamsBg.jpg'

const TeamsPage = () => (
  <Layout>
    <SEO title="Teams" keywords={[`project`, `team`, `people`]} />
    <PageHeader img={teamsImg}>
      <Banner title="Our Teams" subtitle="Join one or more of our teams" />
    </PageHeader>

  </Layout>
)

export default TeamsPage